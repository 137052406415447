import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button } from './Button';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <p>{`Button make common actions more obvious and help users more easily perform them. Buttons use labels and sometimes icons to communicate the action that will occur when the user touches them.`}</p>
    <h3 {...{
      "id": "best-practices"
    }}>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Group buttons logically into sets based on usage and importance.`}</li>
      <li parentName="ul">{`Ensure that button actions are clear and consistent.`}</li>
      <li parentName="ul">{`The main action of a group set can be a primary button.`}</li>
      <li parentName="ul">{`Select a single button variation and do not mix them.`}</li>
    </ul>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Button} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Button>Click me</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button
    }} mdxType="Playground">
  <Button mdxType="Button">Click me</Button>
    </Playground>
    <h2 {...{
      "id": "with-different-sizes"
    }}>{`With different sizes`}</h2>
    <Playground __position={2} __code={'<Button scale=\"small\">Click me</Button>\n<Button scale=\"normal\">Click me</Button>\n<Button scale=\"big\">Click me</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button
    }} mdxType="Playground">
  <Button scale="small" mdxType="Button">Click me</Button>
  <Button scale="normal" mdxType="Button">Click me</Button>
  <Button scale="big" mdxType="Button">Click me</Button>
    </Playground>
    <h2 {...{
      "id": "with-different-colors"
    }}>{`With different colors`}</h2>
    <Playground __position={3} __code={'<Button kind=\"primary\">Click me</Button>\n<Button kind=\"secondary\">Click me</Button>\n<Button kind=\"cancel\">Click me</Button>\n<Button kind=\"dark\">Click me</Button>\n<Button kind=\"gray\">Click me</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button
    }} mdxType="Playground">
  <Button kind="primary" mdxType="Button">Click me</Button>
  <Button kind="secondary" mdxType="Button">Click me</Button>
  <Button kind="cancel" mdxType="Button">Click me</Button>
  <Button kind="dark" mdxType="Button">Click me</Button>
  <Button kind="gray" mdxType="Button">Click me</Button>
    </Playground>
    <h2 {...{
      "id": "outlined"
    }}>{`Outlined`}</h2>
    <Playground __position={4} __code={'<Button kind=\"primary\" outline>\n  Click me\n</Button>\n<Button kind=\"secondary\" outline>\n  Click me\n</Button>\n<Button kind=\"cancel\" outline>\n  Click me\n</Button>\n<Button kind=\"dark\" outline>\n  Click me\n</Button>\n<Button kind=\"gray\" outline>\n  Click me\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button
    }} mdxType="Playground">
  <Button kind="primary" outline mdxType="Button">Click me</Button>
  <Button kind="secondary" outline mdxType="Button">Click me</Button>
  <Button kind="cancel" outline mdxType="Button">Click me</Button>
  <Button kind="dark" outline mdxType="Button">Click me</Button>
  <Button kind="gray" outline mdxType="Button">Click me</Button>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      